const typographyConfig = {
  fontFamily: "Jost, Sans-Serif",
  h1: {
    fontSize: 38,
    fontWeight: 900,
    "@media (min-width:600px)": {
      fontSize: 42,
    },
    "@media (min-width:900px)": {
      fontSize: 48,
    },
  },
  h2: {
    fontSize: 34,
    fontWeight: 700,
    "@media (min-width:600px)": {
      fontSize: 38,
    },
    "@media (min-width:900px)": {
      fontSize: 42,
    },
  },
  h2neon: {
    fontSize: 34,
    fontWeight: 700,
    color: "#89FBFF",
    textShadow: "#89fbff 0 0 3px",
    "@media (min-width:600px)": {
      fontSize: 38,
    },
    "@media (min-width:900px)": {
      fontSize: 42,
    },
  },
  h3: {
    fontSize: 30,
    fontWeight: 700,
    "@media (min-width:600px)": {
      fontSize: 34,
    },
    "@media (min-width:900px)": {
      fontSize: 38,
    },
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    "@media (min-width:600px)": {
      fontSize: 28,
    },
    "@media (min-width:900px)": {
      fontSize: 32,
    },
  },
  h5: {
    fontSize: 22,
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: 24,
    },
    "@media (min-width:900px)": {
      fontSize: 28,
    },
  },
  h6: {
    fontSize: 20,
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: 22,
    },
    "@media (min-width:900px)": {
      fontSize: 24,
    },
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: 20,
    },
    "@media (min-width:900px)": {
      fontSize: 20,
    },
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
  },
};

export default typographyConfig;
