// export default theme;
import { createTheme } from "@mui/material/styles";

import typographyConfig from "./muiTheme/typography";

const theme = createTheme({
  palette: {
    primary: {
      main: "#89FBFF",
    },
    secondary: {
      main: "#1A202B",
    },
    tertiary: {
      main: "#FFFEBF",
    },
    neutral: {
      main: "#ffffff",
    },
    white60: {
      main: "rgba(255, 255, 255, 0.6)",
    },
  },
  typography: typographyConfig,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: "linear-gradient(155.88deg, #FFFD91 8.63%, #98FAFD 94.25%)",
            color: "#000", // Optional: Ensure text is visible over the gradient
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h2neon: "h2",
        },
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            textShadow: "#89fbff 0 0 3px",
          },
        },
      ],
    },
  },
});

export default theme;
